<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model :model="form" :colon="false" layout="inline" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-input placeholder="设计编号" v-model="form.contractCode" style="width: 150px" />
            </a-form-model-item>
            <a-form-model-item label="">
              <a-input placeholder="分包名称" v-model="form.name" style="width: 150px" />
            </a-form-model-item>

            <a-form-model-item>
              <a-select placeholder="分包属性" v-model="form.isneeded" style="width: 150px">
                <a-select-option v-for="item in [
                                                    {
                                                      value: 1,
                                                      name: '设计类-必要分包',
                                                    },
                                                    {
                                                      value: 0,
                                                      name: '设计类-非必要分包',
                                                    },
                                                    {
                                                      value: 2,
                                                      name: '总承包类分包',
                                                    },
                                                  ]" :key="item.value" :value="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-select placeholder="状态" v-model="form.status" style="width: 150px">
                <a-select-option v-for="item in statusList" :key="item.value" :value="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
                <a-button @click="searchVisible = true" type="primary">
                  高级搜索
                </a-button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <a-button v-if="$getPermission($route.path + '/add')" @click="$router.push($route.path + '/add')"
                type="primary">发起审批</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Search :visible="searchVisible" @setVisible="searchVisible = false" @search="onSearch"
        :searchValues="searchValues" />

      <Padding />

      <a-table bordered :dataSource="list" :loading="loading" @change="onChange" :pagination="{
                      total,
                      current,
                      pageSize,
                      showTotal: (total) => `共 ${total} 条记录`,
                    }" :scroll="scroll">
        <a-table-column title="关联合同" data-index="contractCode">
        </a-table-column>
        <a-table-column title="关联合同名称" data-index="contractName">
        </a-table-column>

        <a-table-column title="分包名称" data-index="name"> </a-table-column>

        <a-table-column title="合同金额(万元)" align="right">
          <template slot-scope="text">
            <Money :money="text.planAmount" />
          </template>
        </a-table-column>
        <a-table-column title="分包方" data-index="companyName" align="center">
        </a-table-column>
        <a-table-column title="分包属性" align="center">
          <template slot-scope="text">
            <span v-if="text.isneeded === 1">设计类-必要分包</span>
            <span v-if="text.isneeded === 0">设计类-非必要分包</span>
            <span v-if="text.isneeded === 2">总承包类分包</span>
          </template>
        </a-table-column>
        <a-table-column title="分包类别" align="center">
          <template slot-scope="text">
            <span v-if="text.isneeded === 1">
              <DataDictFinder dictType="subType" :dictValue="text.type" />
            </span>
            <span v-if="text.isneeded === 0" class="center">
              <DataDictFinder dictType="notNeedSubType" :dictValue="text.type" />
              <template v-if="text.major">
                (
                <DataDictFinder dictType="majorTypes" :dictValue="text.major" />
                )
              </template>
            </span>
            <span v-if="text.isneeded === 2">
              <DataDictFinder dictType="generalSubType" :dictValue="text.type" />
            </span>
          </template>
        </a-table-column>

        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="taskReviewStatus" :dictValue="text.status" iconType="badge" />
          </template>
        </a-table-column>

        <a-table-column align="right" title="操作" width="160px" fixed="right">
          <template slot-scope="text">
            <a-space>
              <!-- 已通过 才可以签章 -->
              <a href="#" v-if="
                                              $getPermission($route.path + '/sign') &&
                                              text.status === 'approved'
                                            " @click.prevent="signText(text)">签章</a>
              <!-- 签订中 才可以归档 -->
              <a href="#" v-if="
                                              $getPermission($route.path + '/sign') &&
                                              text.status === 'signing'
                                            " @click.prevent="fileText(text)">归档</a>

              <a href="#" v-if="
                                              $getPermission($route.path + '/sign') &&
                                              (text.status === 'approved' ||
                                                text.status === 'signed' ||
                                                text.status === 'signing')
                                            " @click.prevent="
                                                                $router.push($route.path + '/detail?id=' + text.id)
                                                              ">详情</a>

              <a href="#" v-if="
                                              $getPermission($route.path + '/delete') &&
                                              text.status !== 'cancelled'
                                            " class="danger" @click.prevent="cancelText(text)">作废</a>
              <a href="#" v-if="$getPermission($route.path + '/delete')" @click.prevent="deleteText(text)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import screen from "@/mixins/screen";
import watermark from "@/mixins/watermark";
import { fetchList, changeStatus } from "@/api/subpackage-register";
import { mapGetters } from "vuex";
import Search from "./components/search";

export default {
  name: "subpackage-register",
  mixins: [screen, watermark],

  components: {
    Search,
  },

  data() {
    return {
      form: {},
      searchVisible: false, // 高级搜索框显示隐藏
      searchValues: {}, // 搜索的表单

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statusList() {
      return this.findDataDict("subpackageStatus");
    },
  },

  activated() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form, searchValues } = this;

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        ...form,
        ...searchValues,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.searchValues = {};
      this.getList();
    },
    reset() {
      this.form = {};
      this.searchValues = {};
      this.current = 1;
      this.getList();
    },
    onSearch(params) {
      this.current = 1;
      this.searchValues = params;
      this.form = {};
      this.getList();
    },

    signText(text) {
      const that = this;
      this.$confirm({
        title: "确认要签章吗？",
        onOk() {
          changeStatus({
            id: text.id,
            status: "signing",
          }).then(() => {
            that.getList();
          });
        },
      });
    },

    fileText(text) {
      this.$router.push(this.$route.path + "/sign?id=" + text.id);
    },

    cancelText(text) {
      const that = this;
      this.$confirm({
        title: "确认要作废吗？",
        onOk() {
          changeStatus({
            id: text.id,
            status: "invalid",
          }).then(() => {
            that.getList();
          });
        },
      });
    },
    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          changeStatus({
            id: text.id,
            status: "deleted",
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>