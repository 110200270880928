<template>
  <a-modal :visible="visible" title="高级搜索" @cancel="cancel" :footer="null" width="800px">
    <a-form :form="form" layout="inline" @submit="handleSubmit">
      <a-form-item>
        <a-input v-decorator="['contractCode']" style="width: 240px" placeholder="设计编号" />
      </a-form-item>
      <a-form-item>
        <a-input v-decorator="['name']" style="width: 240px" placeholder="分包名称" />
      </a-form-item>

      <a-form-item>
        <a-input v-decorator="['companyName']" style="width: 240px" placeholder="分包方" />
      </a-form-item>

      <a-form-item>
        <a-select v-decorator="['isneeded']" style="width: 240px" placeholder="分包属性">
          <a-select-option v-for="item in [
                      {
                        value: 1,
                        name: '设计类-必要分包',
                      },
                      {
                        value: 0,
                        name: '设计类-非必要分包',
                      },
                      {
                        value: 2,
                        name: '总承包类分包',
                      },
                    ]" :key="item.value" :value="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-select v-decorator="['type']" style="width: 240px" placeholder="分包类别">
          <a-select-option v-for="item in [
                      ...isNecessaryTypeList,
                      ...notNecessaryTypeList,
                      ...generalSubTypeList,
                    ]" :key="item.value" :value="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-select v-decorator="['major']" style="width: 240px" placeholder="专业类型">
          <a-select-option v-for="item in majorTypes" :key="item.value" :value="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-select v-decorator="['status']" style="width: 240px" placeholder="状态">
          <a-select-option v-for="item in statusList" :key="item.value" :value="item.value">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item>
        <a-input v-decorator="['partyZname']" style="width: 240px" placeholder="业主方" />
      </a-form-item>

      <div class="title">合同金额</div>

      <a-form-item>
        <a-input-number :formatter="$inputNumberFormat" v-decorator="['investmentMin']" style="width: 240px"
          placeholder="最小合同额" />
      </a-form-item>

      <a-form-item>
        <a-input-number :formatter="$inputNumberFormat" v-decorator="['investmentMax']" style="width: 240px"
          placeholder="最大合同额" />
      </a-form-item>

      <div class="right">
        <a-space>
          <a-button @click="cancel">取消</a-button>
          <a-button type="primary" html-type="submit">搜索</a-button>
        </a-space>
      </div>
    </a-form>
  </a-modal>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    searchValues: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        const {
          contractCode,
          name,
          companyName,
          isneeded,
          type,
          major,
          status,
          partyZname,
          investmentMin,
          investmentMax,
        } = this.searchValues;

        this.form.setFieldsValue({
          contractCode,
          name,
          companyName,
          isneeded,
          type,
          major,
          status,
          partyZname,
          investmentMin,
          investmentMax,
        });
      }
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    isNecessaryTypeList() {
      return this.findDataDict("subType");
    },
    notNecessaryTypeList() {
      return this.findDataDict("notNeedSubType");
    },
    generalSubTypeList() {
      return this.findDataDict("generalSubType");
    },
    // 专业类型
    majorTypes() {
      return this.findDataDict("majorTypes");
    },
    statusList() {
      return this.findDataDict("subpackageStatus");
    },
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          this.$emit("search", values);
          this.cancel();
        }
      });
    },

    cancel() {
      this.$emit("setVisible");
    },
  },
};
</script>


<style lang="less" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.75);
  padding: 12px 0;
}
</style>